<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Tous les utilisateurs</div>

            <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="adduser">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter un utilisateur
                  </li>
                </router-link>
              </ol>
            </nav>

            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par nom"
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Profil</th>
                    <th>Email</th>
                    <th>Telephone</th>
                    <th>Permissions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, key) in searchFunction" :key="key">
                    <td>{{ user.name }}</td>
                    <td>{{ user.role }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.telephone }}</td>
                    <td>

                      <i
                        class="mdi mdi-eye icone text-info"
                        title="Afficher les détails"
                        data-toggle="modal"
                        :data-target="'#test' + user._id"
                        @click.prevent="onerole(user.role_responsable)"
                        v-if="user.role=='web'"
                      >
                      </i>
                      <span 
                      v-if="user.role!='web'"
                      
                      ><b>"Mobile"</b></span>

                      <!-- Modal -->
                      <div
                        class="modal fade"
                        :id="'test' + user._id"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                <span style="font-size: 13px"
                                  >Role d'utilisateur ({{role_user.nom}})</span
                                >
                                <b></b>
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click.prevent="close()"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                          

                              <div
                                style="margin-top: 20px; margin-bottom: 20px"
                              >
                       
                              </div>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Les permissions d'utilisateur : </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr v-for="(permission, key) in role_user.permissions" :key="key">
                          
                                 <th>
                                  
                                 {{permission.titre}}
                                 
                                </th>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <router-link
                        :to="{ name: 'updateclient', params: { id: user._id } }"
                        ><i
                          class="mdi mdi-pen icone text-success"
                          style="color: green"
                        ></i> </router-link
                      >&nbsp;
                      <i
                        class="mdi mdi-delete icone text-danger"
                        style="color: red"
                        @click="deleteuser(user._id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  data() {
    return {
      users: [],
      search: "",
      permissions:[],
      role_user:{},
    };
  },

  computed: {
    searchFunction() {
      return this.users.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },

  created() {
    this.getusers();
  },
  methods: {


    getusers() {
      HTTP.get("users/getusers")
        .then((response) => {
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },

    onerole(id) {

      console.log(id)

      HTTP
        .get("roles/getrolesbyid/" + id)
        .then((response) => {
          console.log(response.data);
          this.role_user = response.data;

        })
        .then((err) => console.log(err));
    },




    deleteuser(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé l'utilisateur",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("users/deleteuser/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "Utilisateur supprimé",
            });

            this.getusers();
          });
        }
      });
    },
  },
};
</script>
